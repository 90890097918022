import store from '@/store'
import { useRouter } from '@core/utils/utils'

export const useEcommerce = () => {
  // eslint-disable-next-line arrow-body-style
  const addProductInWishlist = productId => {
    return store.dispatch('app-ecommerce/addProductInWishlist', { productId })
  }

  // eslint-disable-next-line arrow-body-style
  const removeProductFromWishlist = productId => {
    return store.dispatch('app-ecommerce/removeProductFromWishlist', { productId })
  }

  // eslint-disable-next-line arrow-body-style
  const addProductInCart = productId => {
    return store.dispatch('app-ecommerce/addProductInCart', { productId })
  }

  // eslint-disable-next-line arrow-body-style
  const removeProductFromCart = productId => {
    return new Promise(resolve => {
      resolve(productId != null)
    })
  }

  return {
    addProductInWishlist,
    removeProductFromWishlist,
    addProductInCart,
    removeProductFromCart,
  }
}

export const useEcommerceUi = () => {
  const { router } = useRouter()

  const toggleProductInWishlist = product => {
    const { addProductInWishlist, removeProductFromWishlist } = useEcommerce()
    if (product.isInWishlist) {
      removeProductFromWishlist(product.id).then(() => {
        // eslint-disable-next-line no-param-reassign
        product.isInWishlist = false
      })
    } else {
      addProductInWishlist(product.id).then(() => {
        // eslint-disable-next-line no-param-reassign
        product.isInWishlist = true
      })
    }
  }

  const handleCartActionClick = product => {
    // const { addProductInCart } = useEcommerce()
    let _product
    if (!product[0]) _product = product
    else [_product] = product

    if (_product.isInCart) {
      router.push({ name: 'apps-e-commerce-checkout' })
    } else {
      /* eslint no-underscore-dangle: 0 */
      // addProductInCart(_product._id).then(() => {
      // eslint-disable-next-line no-param-reassign
      _product.isInCart = true
      let cart = localStorage.getItem('cart')
      if (!cart) {
        // Si no hay carrito, crear uno nuevo
        cart = []
      } else {
        // Si hay un carrito existente, convertirlo de JSON a objeto JavaScript
        cart = JSON.parse(cart)
      }
      const findProductInCart = cart.findIndex(p => p._id === _product._id)
      if (findProductInCart !== -1) cart[findProductInCart].qty += 1
      else {
        const theproduct = _product
        theproduct.qty = 1
        cart.push(theproduct)
      }
      localStorage.setItem('cart', JSON.stringify(cart))

      // Update cart items count
      // store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', store.state['app-ecommerce'].cartItemsCount + 1)
      // })
    }
  }

  const handleWishlistCartActionClick = (product, removeProductFromWishlistUi) => {
    const { addProductInCart, removeProductFromWishlist } = useEcommerce()

    if (product.isInCart) {
      router.push({ name: 'apps-e-commerce-checkout' })
    } else {
      addProductInCart(product.id)
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          product.isInCart = true
          removeProductFromWishlist(product.id)

          // Update cart items count
          store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', store.state['app-ecommerce'].cartItemsCount + 1)
        })
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          product.isInWishlist = false
          removeProductFromWishlistUi(product)
        })
    }
  }
  return {
    toggleProductInWishlist,
    handleCartActionClick,
    handleWishlistCartActionClick,
  }
}
